import React from 'react';
import { Link } from 'gatsby';
import {
  twitterUrl,
  facebookUrl,
} from '../../constants';

const Footer = () => (
  <div className="footer-container">
    <img className="footer-logo" src="/img/logo-footer.svg" alt="Knapsac Logo" />
    <div className="footer-description">
      <p>
        All Rights Reserved Knapsac Limited 2020<br />
        Company registered in England &amp; Wales, number 10640638.
      </p>
      <p className="footer-policies">
        <Link className="footer-link" to="/privacy-policy">Privacy Policy</Link>
        &nbsp;|&nbsp;
        <Link className="footer-link" to="/cookie-policy">Cookie Policy</Link>
      </p>
    </div>
    <div className="footer-social-container">
      <p>Follow us</p>
      <div className="footer-social-icons">
        <a href={twitterUrl}><img className="footer-social-icon" src="/img/social-media/twitter-icon.svg" alt="Twitter icon" /></a>
        <a href={facebookUrl}><img className="footer-social-icon" src="/img/social-media/facebook-icon.svg" alt="Facebook icon" /></a>
      </div>
    </div>
  </div>
);

export default Footer;
