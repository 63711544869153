import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from 'reactstrap';
import Navbar from '../navbar';
import Footer from '../footer';

// Import Sass entry point. This is handled by Webpack, thanks to the Gatsby
// Sass plugin.
import '../../styles/index.scss';

// Implements smooth scrolling for all anchor links in the application
if (typeof window !== 'undefined') {
  const SmoothScroll = require('smooth-scroll'); // eslint-disable-line global-require

  new SmoothScroll('a[href*="#"]', { speed: 200 }); // eslint-disable-line no-new
}

// Main layout component. All page components should be rendered as children of
// an instance of this.
const Layout = ({ children, data }) => {
  const siteTitle = data.site.config.title;
  const siteDescription = data.site.config.description;

  return (
    <div className="app">
      <Helmet title={siteTitle}>
        <meta name="description" content={siteDescription} />
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

// Export the component wrapped in a GraphQL query which will fetch the relevant
// data and provide it to the component itself.
export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          ...ConfigSiteTitle
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);
