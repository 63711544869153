import React from 'react';
import { Link } from 'gatsby';
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  Nav,
  Button,
} from 'reactstrap';
import {
  JOIN_US_ID,
  FAQS_ID,
} from '../../constants';

export default class CustomNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => this.setState(state => ({
    isOpen: !state.isOpen,
  }))

  render() {
    const { isOpen } = this.state;

    return (
      <Navbar expand="md">
        <Container>

          <NavbarBrand to="/" tag={Link}>
            <img src="/img/knapsac-red.svg" alt="Knapsac Logo" />
          </NavbarBrand>

          {!isOpen
            ? <NavbarToggler className="navbar-toggle-btn navbar-light custom-toggler" onClick={this.toggle} />
            : <Button close className="btn navbar-toggler navbar-toggle-btn navbar-close" onClick={this.toggle} />
          }

          <Collapse navbar isOpen={isOpen}>
            <Nav navbar>
              <NavItem>
                <Link className="nav-link" activeClassName="active" to="/">Home</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" activeClassName="active" to="/blog">Blog</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" activeClassName="active" to={`/#${FAQS_ID}`}>FAQs</Link>
              </NavItem>
            </Nav>
          </Collapse>

        </Container>
      </Navbar>
    );
  }
}
